<template>
  <div>
    <!-- 社群留言 -->
    <FbMessagesDataTable
      :merchandiseId="merchandiseId"
      :fieldFilter="fieldFilter"
      :participants="participants"
      @notifyParentFromFbMessagesDataTable="notifyParentFromFbMessagesDataTable"
    ></FbMessagesDataTable>
    <hr />
    <!-- 所有訂單 -->
    <p class="col-6 tw-border-start fw-bolder mb-3 w-100">
      此商品所有訂單<span class="text-danger"
        >(若有操作「編輯」「斷貨」或「刪除」，請重新整理，這樣FB留言才能更新)</span
      >
    </p>
    <div class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1">
        <!-- 批次操作 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchOrderBatch"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            批次操作
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchOrderBatch">
            <li>
              <button
                class="dropdown-item"
                @click="showMerchOrderModal('soldOut')"
              >
                斷貨
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showMerchOrderModal('setAllocatedAmount', 'max')"
              >
                全數配單
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showMerchOrderModal('setAllocatedAmount', 'min')"
              >
                配單歸 0
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showMerchOrderModal('del')">
                刪除
              </button>
            </li>
          </ul>
        </div>
        <!-- 執行 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchOrderAction"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            執行
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchOrderAction">
            <li>
              <button
                class="dropdown-item"
                @click="showMerchOrderModal('exportExcel')"
              >
                匯出
              </button>
            </li>
          </ul>
        </div>
        <!-- 常用搜尋 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="FrequentlySearched"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            常用搜尋
          </button>
          <ul class="dropdown-menu" aria-labelledby="FrequentlySearched">
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('配單數 = 0')"
              >
                配單數 = 0
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('部分配單')"
              >
                部分配單
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('完全配單')"
              >
                完全配單
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('配單數 > 0')"
              >
                配單數 > 0
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('結單數 = 0')"
              >
                結單數 = 0
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('部分結單')"
              >
                部分結單
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('完全結單')"
              >
                完全結單
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('結單數 > 0')"
              >
                結單數 > 0
              </button>
            </li>
          </ul>
        </div>
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showMerchOrderModal('advancedSearch')"
        >
          進階搜尋
        </button>
        <!-- 切換新舊版訂單管理 -->
        <!-- <button
          class="me-1 mb-1 tw-btn tw-btn-hover"
          :class="{
            'tw-btn-danger': newDataTable,
            'tw-btn-success': !newDataTable,
          }"
          @click="switchDataTableVersion"
        >
          <span v-if="newDataTable">切換舊版訂單</span>
          <span v-else>切換新版訂單</span>
        </button> -->
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <template v-if="recordAdvancedSearchDataForMerchOrder">
          <p>
            搜尋條件:
            <span
              class="badge bg-primary me-1 mb-1"
              v-if="recordAdvancedSearchDataForMerchOrder.merchandiseName"
            >
              商品名稱:
              {{ recordAdvancedSearchDataForMerchOrder.merchandiseName }}
            </span>
            <span
              class="badge bg-primary me-1 mb-1"
              v-if="recordAdvancedSearchDataForMerchOrder.supplier"
            >
              供應商: {{ recordAdvancedSearchDataForMerchOrder.supplier.name }}
            </span>
            <span
              class="badge bg-primary me-1 mb-1"
              v-if="recordAdvancedSearchDataForMerchOrder.stylesName"
            >
              款式名稱: {{ recordAdvancedSearchDataForMerchOrder.stylesName }}
            </span>
            <span class="badge bg-primary me-1 mb-1">
              訂單狀態:
              {{
                recordAdvancedSearchDataForMerchOrder.singleSelect.orderStatus
              }}</span
            >
            <span
              class="badge bg-primary me-1 mb-1"
              v-if="
                recordAdvancedSearchDataForMerchOrder.singleSelect
                  .allocatedStatus !== '不選擇'
              "
            >
              配單狀態:
              {{
                recordAdvancedSearchDataForMerchOrder.singleSelect
                  .allocatedStatus
              }}
            </span>
            <span
              class="badge bg-primary me-1 mb-1"
              v-if="
                recordAdvancedSearchDataForMerchOrder.singleSelect
                  .checkoutStatus !== '不選擇'
              "
            >
              結單狀態:
              {{
                recordAdvancedSearchDataForMerchOrder.singleSelect
                  .checkoutStatus
              }}
            </span>
            <span
              class="badge bg-primary me-1 mb-1"
              v-if="
                recordAdvancedSearchDataForMerchOrder.singleSelect
                  .paymentStatus !== '不選擇'
              "
            >
              收款狀態:
              {{
                recordAdvancedSearchDataForMerchOrder.singleSelect.paymentStatus
              }}
            </span>
            <span
              class="badge bg-primary me-1 mb-1"
              v-if="
                recordAdvancedSearchDataForMerchOrder.singleSelect
                  .shippedStatus !== '不選擇'
              "
            >
              寄出狀態:
              {{
                recordAdvancedSearchDataForMerchOrder.singleSelect.shippedStatus
              }}
            </span>
            <span
              class="badge bg-primary me-1 mb-1"
              v-if="
                recordAdvancedSearchDataForMerchOrder.singleSelect
                  .buyerStatus !== '不選擇'
              "
            >
              顧客資訊:
              {{
                recordAdvancedSearchDataForMerchOrder.singleSelect.buyerStatus
              }}
            </span>
            <span
              class="badge bg-primary me-1 mb-1"
              v-if="
                recordAdvancedSearchDataForMerchOrder.singleSelect.soldOutStatus !== '不選擇'
              "
            >
              斷貨狀態:
              {{ recordAdvancedSearchDataForMerchOrder.singleSelect.soldOutStatus }}
            </span>
          </p>
          <p>
            <span v-if="recordAdvancedSearchDataForMerchOrder.timeRange.switch">
              搜尋區間:
              <span
                v-if="
                  recordAdvancedSearchDataForMerchOrder.timeRange.startTime &&
                  recordAdvancedSearchDataForMerchOrder.timeRange.endTime
                "
                >{{
                  recordAdvancedSearchDataForMerchOrder.timeRange.startTime
                }}~{{
                  recordAdvancedSearchDataForMerchOrder.timeRange.endTime
                }}</span
              >
              <span
                v-if="
                  recordAdvancedSearchDataForMerchOrder.timeRange.startTime &&
                  !recordAdvancedSearchDataForMerchOrder.timeRange.endTime
                "
                >{{
                  recordAdvancedSearchDataForMerchOrder.timeRange.startTime
                }}開始</span
              >
              <span
                v-if="
                  !recordAdvancedSearchDataForMerchOrder.timeRange.startTime &&
                  recordAdvancedSearchDataForMerchOrder.timeRange.endTime
                "
                >{{
                  recordAdvancedSearchDataForMerchOrder.timeRange.endTime
                }}以前</span
              >&ensp;
            </span>
          </p>
          <p>
            <span
              v-if="recordAdvancedSearchDataForMerchOrder.DLtimeRange.switch"
            >
              商品收單區間:
              <span
                v-if="
                  recordAdvancedSearchDataForMerchOrder.DLtimeRange.startTime &&
                  recordAdvancedSearchDataForMerchOrder.DLtimeRange.endTime
                "
                >{{
                  recordAdvancedSearchDataForMerchOrder.DLtimeRange.startTime
                }}~{{
                  recordAdvancedSearchDataForMerchOrder.DLtimeRange.endTime
                }}</span
              >
              <span
                v-if="
                  recordAdvancedSearchDataForMerchOrder.DLtimeRange.startTime &&
                  !recordAdvancedSearchDataForMerchOrder.DLtimeRange.endTime
                "
                >{{
                  recordAdvancedSearchDataForMerchOrder.DLtimeRange.startTime
                }}開始</span
              >
              <span
                v-if="
                  !recordAdvancedSearchDataForMerchOrder.DLtimeRange
                    .startTime &&
                  recordAdvancedSearchDataForMerchOrder.DLtimeRange.endTime
                "
                >{{
                  recordAdvancedSearchDataForMerchOrder.DLtimeRange.endTime
                }}以前</span
              >&ensp;
            </span>
          </p>
        </template>
        <p
          v-if="
            (currentParticipantPermission &&
              currentParticipantPermission.accessStoreReport) ||
            !currentParticipantPermission
          "
        >
          獲利摘要:
          <span v-if="profit">{{ profit }}</span>
          <span class="text-danger" v-else>計算中...</span>
        </p>
      </div>
      <!-- new dataTable -->
      <MerchOrderTable
        v-if="newDataTable"
        :showWhitchModal="showWhitchModal"
        :frequentlySearchedStatus="frequentlySearchedStatus"
        :notifyChildForMerchOrder="notifyChildForMerchOrder"
        @advancedSearchData="advancedSearchDataForMerchOrder"
        @profitString="profitString"
      ></MerchOrderTable>
      <!-- old dataTable -->
      <OldMerchOrderDataTalbe
        v-else
        :showWhitchModal="showWhitchModal"
        :frequentlySearchedStatus="frequentlySearchedStatus"
        :notifyChildForMerchOrder="notifyChildForMerchOrder"
        @advancedSearchData="advancedSearchDataForMerchOrder"
        @profitString="profitString"
      >
      </OldMerchOrderDataTalbe>
    </div>
  </div>
</template>

<script>
import FbMessagesDataTable from '@/components/merchandise/FbMessagesDataTable.vue'
import MerchOrderTable from '../../../components/merchOrder/MerchOrderTable.vue' // new
import OldMerchOrderDataTalbe from '../../../components/merchOrder/OldMerchOrderTable.vue' // old
import FieldFilter from '../../../components/tools/FieldFilter.vue'
// mixin
import { Permission } from '@/methods/mixins/permission';

export default {
  components: { MerchOrderTable, OldMerchOrderDataTalbe, FieldFilter, FbMessagesDataTable },
  data() {
    return {
      merchandiseId: 0,
      // 留言列表
      socialMessages: [],
      // 賣場所有顧客
      participants: {
        loading: false,
        data: []
      },
      merchandiseInfo: {},
      // * merchorderDataTable
      // 通知子層打開 modal 用
      showWhitchModal: {
        soldOut: false,
        setAllocatedAmount: false,
        del: false,
        editOrder: false,
        exportExcel: false,
        advancedSearch: false,
      },
      frequentlySearchedStatus: null,
      profit: null,
      recordAdvancedSearchDataForMerchOrder: null,
      notifyChildForMerchOrder: {
        reloadDataTable: false,
      },
      newDataTable: true,
    }
  },
  mixins: [Permission],
  created() {
    this.initialization()
    this.getPrefer()
    this.getAllParticipants()
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-fbMessages-local')
  },
  computed: {
    // 偏好 > 表格欄位
    fieldFilter() {
      return this.$store.state.Prefer.p_fb.fieldFilter
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.merchandiseId = parseInt(this.$route.query.merchandiseId)
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得賣場所有顧客
    getAllParticipants() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getParticipants{getUser}}'
        }
      ]
      this.participants = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.participants = {
              loading: false,
              data: JSON.parse(JSON.stringify(storeInfo.participants))
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 來自<FB留言與訂單的 dataTable>子層的通知
    notifyParentFromFbMessagesDataTable(object) {
      if (object.reloadMerchOrderDataTable) this.notifyChildForMerchOrder.reloadDataTable = true
      if (object.reloadPrticipants) this.getAllParticipants()
    },
    // * merchOrderDataTable
    // merchOrder 的 modal
    showMerchOrderModal(status, item) {
      if (item) this.showWhitchModal[status] = [true, item]
      else this.showWhitchModal[status] = true
    },
    // 常用搜尋
    frequentlySearched(frequentlySearchedStatus) {
      this.frequentlySearchedStatus = frequentlySearchedStatus
    },
    // 來自<訂單的 dataTable>子層的通知 (進階搜尋)
    advancedSearchDataForMerchOrder(object) {
      this.recordAdvancedSearchDataForMerchOrder = JSON.parse(JSON.stringify(object))
    },
    // 來自<訂單的 dataTable>子層的通知 (獲利摘要)
    profitString(value) {
      this.profit = value
    },
    // 切換 dataTable 版本
    switchDataTableVersion() {
      this.newDataTable = !this.newDataTable
    },
  }
}
</script>